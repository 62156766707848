@import url(https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c);
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.defaultBorder {
    border: 1px dashed black;
    width: 65px;
    height: 65px;
}
.defaultBorder:hover {
    border: 1px dashed black;
    width: 65px;
    height: 65px;
    background-color: #f9c3f8;
}
.fixedDefaultBorder {
    border: 1px solid black;
    width: 65px;
    height: 65px;
    background-color: #f9c3f8;
}
.nullBorder {
    border: 1px dashed white;
    width: 65px;
    height: 65px;
}

.history-detail-table {
    margin-top: 10px;
}
.history-detail-table td {
    color: white;
    font-size: 10px;
    width: 84px;
    white-space: nowrap;
    text-align: center;
    padding: 4px 0 !important;
    font-weight: 700;
}

.Calendar {
    border-collapse: collapse;
    padding: 0;
    margin: 1rem auto 0;
    background: #fff;
}

.Calendar th,
.Calendar td {
    padding: .25rem;
    text-align: center;
}

.Calendar th {
    background: #ddd;
    padding: .5rem 1rem;
}

.Calendar .month-header {
    border-bottom: 1px solid #fff;
}

.Calendar .nav .nav-inner {
  cursor: pointer;
  border: 0;
  background: none transparent;
}

.Calendar .Day {
    cursor: pointer;
    border: 1px solid #888888;
}

.Calendar .Day .Day-inner {
    background: none transparent;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    height: 3.0rem;
    line-height: 3.0rem;
    width: 3.5rem;
}

.Calendar .Day button.Day-inner {
    border: 0;
}

.Calendar .Day a.Day-inner {
    color: inherit;
}

.Calendar .Day.weekend {
    background: #bdc3c7;
    color: #000;
}

.Calendar .Day.other-month {
    color: #999;
    font-style: italic;
}

.Calendar .Day.today .Day-inner {
    background: #fafad2;
    color: #000;
}

.Calendar .Day:hover .Day-inner {
    background: #eee;
    color: #000;
}

.Calendar .Day.selected .Day-inner {
    background: #2980b9;
    color: #fff;
}


/*
.mark_1 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) 0px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_2 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -25px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_3 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -50px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_4 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -75px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_5 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -100px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_6 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -125px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_7 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -150px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_8 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -175px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_9 { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -200px 0px !important; display:inline-block; vertical-align:text-bottom; }
.mark_ck { width: 24px; height: 24px; background: url(/imgms/moshi/mark.png) -225px 0px !important; display:inline-block; vertical-align:text-bottom; }
*/
#gcateg {
	width:838px;
	position:relative;
}

#gcateg .seiseki {
	width:553px;
	/*background: url(../imgms/sct/kamoku_arrow.png) no-repeat right 160px;*/
}
#gcateg .seiseki p span {
	color:#4b95b6;
	padding-right:5px;
}

#gcateg .seiseki p a {
	font-size:100%;
}

#gcateg .seiseki table {
	border-collapse: collapse;
	width:510px;
}
#gcateg .seiseki table th,
#gcateg .seiseki table td{
	background-color:#f9f9f9;
	padding:4px 8px;
	letter-spacing:1px;
	text-align:center;
	font-size:16px;
	color:#555;
	line-height:1.6em;
	border:1px solid #CCC;
}

#gcateg .seiseki table td{
	background:#def1f9;
	line-height:18px;
}
#gcateg .seiseki table th{
	background:#def1f9;
	color:#555555;
	text-align:left;
	white-space:nowrap;
	font-weight:normal;
}
#gcateg .seiseki table .t-sb{
	min-width: 320px;
}
#gcateg .seiseki table .t-1{
	background:#fff;
}
#gcateg .seiseki table .t-2{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gcateg .seiseki table .t-3{
	color: #C60;
}
#gcateg .seiseki table .t-4{
	color: #0083d6;
}
#gcateg .seiseki table .t-5{
	color: #C60;
	background:#fff2ec;
}
#gcateg .seiseki table .t-6{
	color: #C60;
	background:#dce4e7;
}
#gcateg .seiseki table .t-21{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
	width:340px;
}


/*#gcatde .detail {*/
/*	width:268px;*/
/*	position:absolute;*/
/*	right:0;*/
/*	top:0;*/
/*}*/
/*#gcatde .detail p span {*/
/*	color:#cca746;*/
/*	padding-right:5px;*/
/*}*/

#gcatde .detail table {
	width:267px;
	border-collapse: collapse;
}
#gcatde .detail table th,
#gcatde .detail table td{
	background-color:#f9f9f9;
	padding:4px 4px;
	letter-spacing:1px;
	text-align:center;
	font-size:16px;
	color:#555;
	border:1px solid #CCC;
}

#gcatde .detail table td{
	background:#f5ecd4;
	line-height:24px;
}
#gcatde .detail table th{
	background:#f5ecd4;
	color:#555555;
	white-space:nowrap;
}
#gcatde .detail .t-1{
	background:#fff;
}
#gcatde .detail .t-2{
	background:#cca746;
	color:#FFF;
	font-weight:normal;
	padding:4px;
}
#gcatde .detail .t-3{
	color: #C60;
}
#gcatde .detail .t-4{
	color: #0083d6;
}
#gcatde .detail .t-5{
	background: #fff2ec;
	color: #C60;
}
#gcatde .detail .t-6{
	background: #f7e0c4;
	color: #C60;
}

#gsched table {
	/*width:838px;*/
	width:960px;
	border-collapse: collapse;
	text-align: left;
	color: #90C;
}
#gsched table th,
#gsched table td{
	background-color:#f9f9f9;
	padding:4px;
	letter-spacing:1px;
	text-align:center;
	font-size:14px;
	color:#555;
	line-height:1.8;
	border:1px solid #CCC;
}

#gsched table td{
	background:#def1f9;
}
#gsched table th{
	background:#def1f9;
	color:#555555;
	text-align:left;
	white-space:nowrap;
	font-weight:normal;
}
#gsched .t-0{
	border-left:none;
	border-top:none;
	background:#fff;
}
#gsched .subj{
	max-width: 320px;
}
#gsched .t-1{
	background:#fff;
}
#gsched .t-2{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gsched .t-3{
	color: #C60;
}
#gsched .t-4{
	color: #0083d6;
}
#gsched .t-5{
	color: #1d5d38;
	background:#b5d6b9;
}
#gsched .t-6{
	color: #424523;
	background:#cdc39d;
}
#gsched .t-7{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gsched .t-8{
	background:#e4e4e4;
	color:#1f6a8c;
	font-weight:normal;
}
#gsched .t-9{
	background:#e4e4e4;
}
#gsched .t-10{
	background:#f1f7f2;
}
#gsched .t-11{
	background:#d0eaec;
}
#gsched .t-12{
	background:#f6f4ed;
}
#gsched .t-13{
	background:#d5e6e8;
}

#gschedprint table {
	/*width:838px;*/
	width:1100px;
	max-width: 1100px;
	border-collapse: collapse;
	text-align: left;
	color: #90C;
	margin-left: auto;
	margin-right: auto;
}
#gschedprint table th,
#gschedprint table td{
	background-color:#f9f9f9;
	padding:4px;
	letter-spacing:1px;
	text-align:center;
	font-size:12px;
	color:#555;
	line-height:1.8;
	border:1px solid #CCC;
}

#gschedprint table td{
	background:#def1f9;
}
#gschedprint table th{
	background:#def1f9;
	color:#555555;
	text-align:left;
	white-space:nowrap;
	font-weight:normal;
}
#gschedprint .t-0{
	border-left:none;
	border-top:none;
	background:#fff;
}
#gschedprint .subj{
	max-width: 520px;
}
#gschedprint .t-1{
	background:#fff;
}
#gschedprint .t-2{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gschedprint .t-3{
	color: #C60;
}
#gschedprint .t-4{
	color: #0083d6;
}
#gschedprint .t-5{
	color: #1d5d38;
	background:#b5d6b9;
}
#gschedprint .t-6{
	color: #424523;
	background:#cdc39d;
}
#gschedprint .t-7{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gschedprint .t-8{
	background:#e4e4e4;
	color:#1f6a8c;
	font-weight:normal;
}
#gschedprint .t-9{
	background:#e4e4e4;
}
#gschedprint .t-10{
	background:#f1f7f2;
}
#gschedprint .t-11{
	background:#d0eaec;
}
#gschedprint .t-12{
	background:#f6f4ed;
}
#gschedprint .t-13{
	background:#d5e6e8;
}

@media print {
	#gschedprint .endprintbtn {
		display: none;
	}
}

#gcatprint {
	position:relative;
}

#gcatprint .seiseki {
	/*width:450px;*/
	width:330px;
	/*background: url(../imgms/sct/kamoku_arrow.png) no-repeat right 160px;*/
}

#gcatprint .seiseki table {
	border-collapse: collapse;
	width:100%;
}
#gcatprint .seiseki table th,
#gcatprint .seiseki table td{
	background-color:#f9f9f9;
	padding:2px 4px;
	letter-spacing:1px;
	text-align:center;
	font-size:0.8rem;
	color:#555;
	line-height:1em;
	border:1px solid #CCC;
}

#gcatprint .seiseki table td{
	background:#def1f9;
	line-height:18px;
}
#gcatprint .seiseki table th{
	background:#def1f9;
	color:#555555;
	text-align:left;
	white-space:nowrap;
	font-weight:normal;
}
#gcatprint .seiseki table .t-1{
	background:#fff;
}
#gcatprint .seiseki table .t-2{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gcatprint .seiseki table .t-3{
	color: #C60;
}
#gcatprint .seiseki table .t-4{
	color: #0083d6;
}
#gcatprint .seiseki table .t-5{
	color: #C60;
	background:#fff2ec;
}
#gcatprint .seiseki table .t-6{
	color: #C60;
	background:#dce4e7;
}
#gcatprint .seiseki table .t-21{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}

.wf-roundedmplus1c { font-family: "M PLUS Rounded 1c"; }
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


