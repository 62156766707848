.Calendar {
    border-collapse: collapse;
    padding: 0;
    margin: 1rem auto 0;
    background: #fff;
}

.Calendar th,
.Calendar td {
    padding: .25rem;
    text-align: center;
}

.Calendar th {
    background: #ddd;
    padding: .5rem 1rem;
}

.Calendar .month-header {
    border-bottom: 1px solid #fff;
}

.Calendar .nav .nav-inner {
  cursor: pointer;
  border: 0;
  background: none transparent;
}

.Calendar .Day {
    cursor: pointer;
    border: 1px solid #888888;
}

.Calendar .Day .Day-inner {
    background: none transparent;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    height: 3.0rem;
    line-height: 3.0rem;
    width: 3.5rem;
}

.Calendar .Day button.Day-inner {
    border: 0;
}

.Calendar .Day a.Day-inner {
    color: inherit;
}

.Calendar .Day.weekend {
    background: #bdc3c7;
    color: #000;
}

.Calendar .Day.other-month {
    color: #999;
    font-style: italic;
}

.Calendar .Day.today .Day-inner {
    background: #fafad2;
    color: #000;
}

.Calendar .Day:hover .Day-inner {
    background: #eee;
    color: #000;
}

.Calendar .Day.selected .Day-inner {
    background: #2980b9;
    color: #fff;
}

