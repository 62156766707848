#gsched table {
	/*width:838px;*/
	width:960px;
	border-collapse: collapse;
	text-align: left;
	color: #90C;
}
#gsched table th,
#gsched table td{
	background-color:#f9f9f9;
	padding:4px;
	letter-spacing:1px;
	text-align:center;
	font-size:14px;
	color:#555;
	line-height:1.8;
	border:1px solid #CCC;
}

#gsched table td{
	background:#def1f9;
}
#gsched table th{
	background:#def1f9;
	color:#555555;
	text-align:left;
	white-space:nowrap;
	font-weight:normal;
}
#gsched .t-0{
	border-left:none;
	border-top:none;
	background:#fff;
}
#gsched .subj{
	max-width: 320px;
}
#gsched .t-1{
	background:#fff;
}
#gsched .t-2{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gsched .t-3{
	color: #C60;
}
#gsched .t-4{
	color: #0083d6;
}
#gsched .t-5{
	color: #1d5d38;
	background:#b5d6b9;
}
#gsched .t-6{
	color: #424523;
	background:#cdc39d;
}
#gsched .t-7{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gsched .t-8{
	background:#e4e4e4;
	color:#1f6a8c;
	font-weight:normal;
}
#gsched .t-9{
	background:#e4e4e4;
}
#gsched .t-10{
	background:#f1f7f2;
}
#gsched .t-11{
	background:#d0eaec;
}
#gsched .t-12{
	background:#f6f4ed;
}
#gsched .t-13{
	background:#d5e6e8;
}
