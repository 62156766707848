#gcateg {
	width:838px;
	position:relative;
}

#gcateg .seiseki {
	width:553px;
	/*background: url(../imgms/sct/kamoku_arrow.png) no-repeat right 160px;*/
}
#gcateg .seiseki p span {
	color:#4b95b6;
	padding-right:5px;
}

#gcateg .seiseki p a {
	font-size:100%;
}

#gcateg .seiseki table {
	border-collapse: collapse;
	width:510px;
}
#gcateg .seiseki table th,
#gcateg .seiseki table td{
	background-color:#f9f9f9;
	padding:4px 8px;
	letter-spacing:1px;
	text-align:center;
	font-size:16px;
	color:#555;
	line-height:1.6em;
	border:1px solid #CCC;
}

#gcateg .seiseki table td{
	background:#def1f9;
	line-height:18px;
}
#gcateg .seiseki table th{
	background:#def1f9;
	color:#555555;
	text-align:left;
	white-space:nowrap;
	font-weight:normal;
}
#gcateg .seiseki table .t-sb{
	min-width: 320px;
}
#gcateg .seiseki table .t-1{
	background:#fff;
}
#gcateg .seiseki table .t-2{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gcateg .seiseki table .t-3{
	color: #C60;
}
#gcateg .seiseki table .t-4{
	color: #0083d6;
}
#gcateg .seiseki table .t-5{
	color: #C60;
	background:#fff2ec;
}
#gcateg .seiseki table .t-6{
	color: #C60;
	background:#dce4e7;
}
#gcateg .seiseki table .t-21{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
	width:340px;
}


/*#gcatde .detail {*/
/*	width:268px;*/
/*	position:absolute;*/
/*	right:0;*/
/*	top:0;*/
/*}*/
/*#gcatde .detail p span {*/
/*	color:#cca746;*/
/*	padding-right:5px;*/
/*}*/

#gcatde .detail table {
	width:267px;
	border-collapse: collapse;
}
#gcatde .detail table th,
#gcatde .detail table td{
	background-color:#f9f9f9;
	padding:4px 4px;
	letter-spacing:1px;
	text-align:center;
	font-size:16px;
	color:#555;
	border:1px solid #CCC;
}

#gcatde .detail table td{
	background:#f5ecd4;
	line-height:24px;
}
#gcatde .detail table th{
	background:#f5ecd4;
	color:#555555;
	white-space:nowrap;
}
#gcatde .detail .t-1{
	background:#fff;
}
#gcatde .detail .t-2{
	background:#cca746;
	color:#FFF;
	font-weight:normal;
	padding:4px;
}
#gcatde .detail .t-3{
	color: #C60;
}
#gcatde .detail .t-4{
	color: #0083d6;
}
#gcatde .detail .t-5{
	background: #fff2ec;
	color: #C60;
}
#gcatde .detail .t-6{
	background: #f7e0c4;
	color: #C60;
}
