.defaultBorder {
    border: 1px dashed black;
    width: 65px;
    height: 65px;
}
.defaultBorder:hover {
    border: 1px dashed black;
    width: 65px;
    height: 65px;
    background-color: #f9c3f8;
}
.fixedDefaultBorder {
    border: 1px solid black;
    width: 65px;
    height: 65px;
    background-color: #f9c3f8;
}
.nullBorder {
    border: 1px dashed white;
    width: 65px;
    height: 65px;
}
