#gcatprint {
	position:relative;
}

#gcatprint .seiseki {
	/*width:450px;*/
	width:330px;
	/*background: url(../imgms/sct/kamoku_arrow.png) no-repeat right 160px;*/
}

#gcatprint .seiseki table {
	border-collapse: collapse;
	width:100%;
}
#gcatprint .seiseki table th,
#gcatprint .seiseki table td{
	background-color:#f9f9f9;
	padding:2px 4px;
	letter-spacing:1px;
	text-align:center;
	font-size:0.8rem;
	color:#555;
	line-height:1em;
	border:1px solid #CCC;
}

#gcatprint .seiseki table td{
	background:#def1f9;
	line-height:18px;
}
#gcatprint .seiseki table th{
	background:#def1f9;
	color:#555555;
	text-align:left;
	white-space:nowrap;
	font-weight:normal;
}
#gcatprint .seiseki table .t-1{
	background:#fff;
}
#gcatprint .seiseki table .t-2{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
#gcatprint .seiseki table .t-3{
	color: #C60;
}
#gcatprint .seiseki table .t-4{
	color: #0083d6;
}
#gcatprint .seiseki table .t-5{
	color: #C60;
	background:#fff2ec;
}
#gcatprint .seiseki table .t-6{
	color: #C60;
	background:#dce4e7;
}
#gcatprint .seiseki table .t-21{
	background:#4b95b6;
	color:#FFF;
	text-align:center;
	font-weight:normal;
}
